import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgendarOnboarding } from 'src/app/onboarding/agendar/agendar';
import { SettingsService } from 'src/app/settings/settings.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-visualizar-pdf',
  templateUrl: './visualizar-pdf.component.html',
  styleUrl: './visualizar-pdf.component.less',
  encapsulation: ViewEncapsulation.None,
})
export class VisualizarPdfComponent implements OnInit {
  documentType!: string;
  isLoading: boolean = true;
  company_id = localStorage.getItem('company_id');
  pdfUrl: SafeResourceUrl | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VisualizarPdfComponent>,
    private settings: SettingsService,
    public snackBar: MatSnackBar,
    private sanitizer: DomSanitizer
  ) {
    this.documentType = data.documentType;
  }

  ngOnInit(): void {
    this.getPfdLink();
  }

  async getPfdLink() {
    this.isLoading = true;
    
    const documentTypeMap: { [key: string]: string } = {
      'Declaración PEP': 'declaracion_PEP',
      'Estado de situación financiera': 'estado_situacion',
      'Declaración jurada': 'declaracion_jurada'
    };
  
    try {
      const documentType = documentTypeMap[this.documentType];
      const response: any = await this.settings.get_query(1, `/onboarding/data-onboarding/${this.company_id}/?type_document=${documentType}`);
      const url = response._body.success.url + '#navpanes=0&scrollbar=1&view=FitH&page=1&pagemode=none';
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

    } catch (error) {
      console.error('Error al obtener link de ', this.documentType, ' :', error);
    } finally {
      this.isLoading = false;
    }
  }
  
  

  agendar() {
    let dialogRef = this.dialog.open(AgendarOnboarding, {
      width: '700px',
      minHeight: '600px',
      autoFocus: false,
    });
  }
}
