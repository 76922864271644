import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IMessage, WebSocketService } from 'src/app/services/websocket.service';
import { DetalleFacturasMalas } from './detalle-facturas/detalle-facturas';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { SettingsService } from 'src/app/settings/settings.service';;

declare var $: any;


@Component({
  selector: 'app-detalle-operacion',
  templateUrl: './detalle-operacion.html',
  styleUrls: ['./detalle-operacion.less']
})
export class DetailOperationComponent implements OnInit {

  id_op: any;
  id_sim: any;
  constructor( private router:Router, public settings: SettingsService, public dialog: MatDialog, private activated_router: ActivatedRoute, private snackBar:MatSnackBar, private ws: WebSocketService) {

    this.activated_router.params.subscribe(params => {
      this.id_op = params['id']
    })

  }

  @Input() id_company:any=localStorage.getItem('company_id');




  myObserver: any;
  suscriptionEvent:any;
  ngOnDestroy() {
    this.myObserver.unsubscribe();
    this.suscriptionEvent.unsubscribe()
  }

  load_all(){
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.get_stepper()
  }


  is_responsive:boolean=false;
  ngOnInit() {


    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }

    this.myObserver = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.load_all();
      }
    });


    this.suscriptionEvent = this.ws.onMessage().subscribe((msg: IMessage) => {
      switch (msg.command) {
        case 'cambios_curse_operacion':
          this.load_all()
          break;
        case 'descarte_curse_operacion':
          this.router.navigate(['home/detalle-operacion/',this.id_sim])
          break;
        default:
          break;
      }
    });

    this.load_all();

  }

  formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? '-' : '';
      const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    } catch (e) {
    }
  }



  date_created="";
  anticipo:any=0;
  valor_anticipo_total:any=0;
  dias_promedio:any=0;
  tasa_interes:any=0;
  comision:any=0;
  gastos:any=0;
  interes_total:any=0;
  iva_operacion:any=0;
  excedentes:any=0;
  monto_recibir:any=0;
  total:any=0;
  cantidad_facturas:any=0;
  STATUS:any;
  loading:boolean=false;
  id_operacion:any=null;
  has_valid_aecs:boolean=false;
  caso:any="";
  async get_resumen_sim(){


    try {

      this.loading = true;

      const bd: any = await this.settings.get_query(1, '/cliente/simulacion/'+this.id_sim+'/');
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:

        console.log("this.gastos", body_data)

          this.STATUS = data.info.status;
          this.cantidad_facturas = data.info.cant_facturas;
          this.has_valid_aecs = data.info.has_valid_aecs;
          this.date_created = data.info.date_created;
          this.anticipo = this.formatMoney(data.info.anticipo);
          this.valor_anticipo_total = this.formatMoney(data.info.valor_anticipo_total);
          this.dias_promedio = data.info.dias_promedio;
          this.tasa_interes = data.info.tasa_interes;
          this.comision = this.formatMoney(data.info.comision);
          this.gastos = this.formatMoney(data.info.gastos);
          this.interes_total = this.formatMoney(data.info.interes_total);
          this.iva_operacion = this.formatMoney(data.info.iva_operacion);
          this.excedentes = this.formatMoney(data.info.excedentes);
          this.monto_recibir = this.formatMoney(data.info.monto_recibir);
          this.total = this.formatMoney(Number(data.info.total));
          this.id_operacion = data.info.id_operacion;

          this.loading = false;



        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }


  }

  monto_girar=0;
  status_text="";
  mensaje="";
  step:any=1;
  is_warning:boolean=false;
  contactar_ejecutivo:boolean=false;
  adjuntar_archivos:boolean=false;
  facturas_con_problema:any[]=[];
  post_verificacion:boolean=false;
  screen:any=1;
  async get_stepper(){

    this.loading = true;

    try {


      const bd: any = await this.settings.get_query(1, '/cliente/curse-operaciones-detalle/'+this.id_op+'/');
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:


          this.id_sim = data.info.operacion.simulacion_id;
          this.status_text = data.info.operacion.status_text;
          this.monto_girar = data.info.operacion.monto_girar;
          this.mensaje = data.info.step_info.mensaje;
          this.step = data.info.step;
          this.is_warning = data.info.step_info.is_warning;
          this.contactar_ejecutivo = data.info.step_info.contactar_ejecutivo;
          this.adjuntar_archivos = data.info.step_info.adjuntar_archivos;
          this.facturas_con_problema = data.info.step_info.facturas_problema;
          this.post_verificacion = data.info.post_verificacion;
          this.caso = data.info.step_info.caso;
          this.screen = data.info.screen;




          this.get_resumen_sim();

  



        break;
        default:

        break;
      }

    } catch (bd:any) {

      console.log(bd)

      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }


  }




  loading_pdf_:boolean=false;
  async download_pdf() {
    try {
      this.loading_pdf_ = true;
  
      const bd: any = await this.settings.get_query(
        1,
        `/cliente/simulacion-detalle-pdf/${this.id_sim}/`
      );
  
      const body_data: any = bd._body;
      const data = body_data.success;
  
      switch (bd.status) {
        case 200:
          console.log('Datos recibidos:', body_data);
  
          if (body_data.respuesta && body_data.respuesta.info) {
            window.open(body_data.respuesta.info, "_blank");
          } else if (data && data.info) {
            window.open(data.info, "_blank");
          } else {
            this.snackBar.open('No se encontró la URL del PDF en la respuesta del servidor', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack',
            });
          }
          this.loading_pdf_ = false;
          break;
  
        default:
          this.loading_pdf_ = false;
          this.snackBar.open('Ocurrió un error al procesar la solicitud', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack',
          });
          break;
      }
    } catch (error: any) {
      console.error('Error capturado:', error);
      this.loading_pdf_ = false;
  
      switch (error.status) {
        case 500:
          this.snackBar.open('Error del servidor, intenta de nuevo', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack',
          });
          break;
  
        case 400:
          this.snackBar.open('Solicitud inválida, intenta de nuevo', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack',
          });
          break;
  
        default:
          this.snackBar.open('Error inesperado, intenta de nuevo', 'Aceptar', {
            duration: 20000,
            panelClass: 'simpli-snack',
          });
          break;
      }
    }
  }
  
  

  volver_a_simular(){
    this.router.navigate(['/home/']);

    setTimeout(() => {
      if( $('#caja_excel').length )
      {
        $("#caja_excel").trigger('click');
      }
    });
  }


  files(){
    console.log(this.id_sim, this.id_op, this.caso)
    this.caso = 2
    this.router.navigate(['home/documentacion/',this.id_sim, this.id_op, this.caso])
  }

  contactar(){
      $("#chatEjecutivo").trigger('click');
  }

  detalle_factura(){
    const dialogRef = this.dialog.open(DetalleFacturasMalas,{
      width: '900px',
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.componentInstance.paginar_front = true;
    dialogRef.componentInstance.facturas = this.facturas_con_problema
    dialogRef.componentInstance.caso = this.caso

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result == true){

      }
    });
  }


  return(){
    this.router.navigate(['home/operaciones'])
  }



}
