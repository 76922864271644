// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // configFirebase: {
  //   apiKey: "AIzaSyBjrZJiSaVlL0--pFF3MnjtIyDkUz_cxVM",
  //   authDomain: "cliente-simpli.firebaseapp.com",
  //   projectId: "cliente-simpli",
  //   storageBucket: "cliente-simpli.appspot.com",
  //   messagingSenderId: "887248832100",
  //   appId: "1:887248832100:web:bf2a11eacfe00d33158171"
  // }
  configFirebase :{
    apiKey: "AIzaSyCGDXlEj2_jHRcwmLmh-9nVDZqx7fEQl04",
    authDomain: "simpli-factoring.firebaseapp.com",
    projectId: "simpli-factoring",
    storageBucket: "simpli-factoring.firebasestorage.app",
    messagingSenderId: "256416355237",
    appId: "1:256416355237:web:f095e2b0587fbdb39b0e4c",
    measurementId: "G-T8D1Y43XLH"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
