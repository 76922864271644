
<div mat-dialog-content *ngIf="se_abrio_como_dialogo == true">


                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-top" style="padding:0px;position: inherit;">
                    <h1 class="titulo-simpli">Cambio de Contraseña</h1>
                    <small class="subtitulo-simpli">Cambia tu contraseña de inicio de sesión</small>
                </div>


                <form  [formGroup]="valForm">

                    <mat-form-field appearance="fill">
                        <mat-label>Contraseña Actual</mat-label>
                        <input formControlName="password" matInput [type]="hide ? 'password' : 'text'" required>
                        <mat-hint>Introduce tu contraseña actual para verificar si esta correcta</mat-hint>
                        <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon style="color: gray;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </mat-form-field>


                    <div style="width: 100%;margin-top: 20px;margin-bottom: 10px;">
                        <button mat-stroked-button  type="button" style="margin-left: 10px;width: 200px;" color="primary" (click)="verify_password()">Verificar</button>
                    </div>



                    <mat-form-field appearance="fill">
                        <mat-label>Contraseña Nueva</mat-label>
                        <input formControlName="new_password"  matInput [type]="hide ? 'password' : 'text'"  required>
                        <mat-hint>Introduce tu contraseña nueva</mat-hint>

                        <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon style="color: gray;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>

                        <mat-error *ngIf="valForm.controls['new_password'].hasError('required') && (valForm.controls['new_password'].dirty || valForm.controls['new_password'].touched)">
                            Este campo es <strong>requerido</strong>
                        </mat-error>

                        <mat-error *ngIf="valForm.controls['new_password'].hasError('minlength')">
                            La contraseña debe ser de al menos 6 carácteres.
                        </mat-error>



                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Repita la Contraseña Nueva</mat-label>
                        <input formControlName="re_new_password"  matInput [type]="hide ? 'password' : 'text'" required>
                        <mat-hint>Repita su contraseña nueva</mat-hint>

                        <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon style="color: gray;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>

                        <mat-error *ngIf="valForm.controls['re_new_password'].hasError('required') && (valForm.controls['re_new_password'].dirty || valForm.controls['re_new_password'].touched)">
                            Este campo es <strong>requerido</strong>
                        </mat-error>

                        <mat-error *ngIf="valForm.get('re_new_password').hasError('equalTo')">
                            Las contraseñas no coinciden
                        </mat-error>
                    </mat-form-field>


                </form>

</div>

<div mat-dialog-actions *ngIf="se_abrio_como_dialogo == true">

            <button mat-dialog-close mat-stroked-button type="button" style="width: 200px;margin-left: 10px;">Cerrar</button>


            <button [disabled]="disabledButton" mat-stroked-button  type="submit" color="primary" style="width: 200px;margin-left: 10px;">Cambiar</button>

</div>

<form  [formGroup]="valForm" *ngIf="se_abrio_como_dialogo == false">

    <mat-form-field appearance="fill">
        <mat-label>Contraseña Actual</mat-label>
        <input formControlName="password" matInput [type]="hide ? 'password' : 'text'" required>
        <mat-hint>Introduce tu contraseña actual para verificar si esta correcta</mat-hint>
        <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon style="color: gray;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
    </mat-form-field>


    <div style="width: 100%;margin-top: 20px;margin-bottom: 20px;margin-bottom: 20px;">
        <button mat-stroked-button  type="button" class="primary-button" color="primary" (click)="verify_password()">Verificar</button>
    </div>



    <mat-form-field appearance="fill">
        <mat-label>Contraseña Nueva</mat-label>
        <input formControlName="new_password"  matInput [type]="hide ? 'password' : 'text'"  required>
        <mat-hint>Introduce tu contraseña nueva</mat-hint>

        <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon style="color: gray;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>

        <mat-error *ngIf="valForm.controls['new_password'].hasError('required') && (valForm.controls['new_password'].dirty || valForm.controls['new_password'].touched)">
            Este campo es <strong>requerido</strong>
        </mat-error>

        <mat-error *ngIf="valForm.controls['new_password'].hasError('minlength')">
            La contraseña debe ser de al menos 6 carácteres.
        </mat-error>



    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Repita la Contraseña Nueva</mat-label>
        <input formControlName="re_new_password"  matInput [type]="hide ? 'password' : 'text'" required>
        <mat-hint>Repita su contraseña nueva</mat-hint>

        <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon style="color: gray;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>

        <mat-error *ngIf="valForm.controls['re_new_password'].hasError('required') && (valForm.controls['re_new_password'].dirty || valForm.controls['re_new_password'].touched)">
            Este campo es <strong>requerido</strong>
        </mat-error>

        <mat-error *ngIf="valForm.get('re_new_password').hasError('equalTo')">
            Las contraseñas no coinciden
        </mat-error>
    </mat-form-field>

    <div style="width: 100%;margin-top: 20px;margin-bottom: 20px;margin-bottom: 20px;">
        <button [disabled]="disabledButton" mat-stroked-button  type="button" (click)="submitForm($event, valForm.value)" color="primary" class="primary-button">Cambiar</button>
    </div>



</form>

