import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

declare var $: any;


@Component({
  selector: 'app-facturador-externo',
  templateUrl: './facturador-externo.html',
  styleUrls: ['./facturador-externo.less']
})
export class FacturadorExterno implements OnInit {


  @Input() id_sim:any;
  @Input() id_company:any;
  parent:any;

  open_as_sim:boolean=false;

  title:string="¡Cesión realizada con éxito!";
  subtitle:string="Revisa el estado de esta etapa de la operación, con sus datos actualizados."

  constructor(public dialog: MatDialog, public dialogRef:MatDialogRef<FacturadorExterno>, public router:Router) {

    this.id_company = localStorage.getItem('company_id');

   }


  is_responsive:boolean=false;
  ngOnInit() {
    if (window.screen.width <= 1024) {
      this.is_responsive = true;
    }

  }

  ver_estado(){
    this.dialogRef.close();
    this.parent.change_step('step-simpli3');
  }

  chat_soporte(){
    let url = "https://api.whatsapp.com/send?phone=56934677786";
    window.open(url, "_blanck")
  }

  cedio:boolean=false;
  ceder(){
    this.cedio = true;
  }

  close(){
    this.dialogRef.close(true)
  }

}