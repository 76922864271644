<div *ngIf="isLoading" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn">
  <loading-interno></loading-interno>
</div>

<div *ngIf="!isLoading">
  <div mat-dialog-title>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn"
      style="position: relative;margin-top: 10px;">
      <div class="close-dialog" mat-dialog-close>
        <i class="fa-regular fa-xmark"></i>
      </div>
      <div class="titulo-onboarding">{{documentType}}</div>
    </div>
  </div>

  <mat-dialog-content style="height: calc(95vh - 120px);">
    <div class="row" style="height: 100%;">
      <iframe [src]="pdfUrl" width="100%" height="100%" frameborder="0" style="cursor: default;"></iframe>


    </div>
  </mat-dialog-content>
  

  <mat-dialog-actions style="margin-bottom: 20px; display: flex; flex-direction: column; align-items: stretch;">

    <div style="display: flex; flex-direction: column; margin-top: 10px;">
      <div style="display: flex; justify-content: center; align-items: center;">
        <div class="contact-container">
          <div>Si necesitas actualizar los datos, contacta a un ejecutivo.</div>
          <div class="icons">
            <span (click)="agendar()"><i class="fa-light fa-video"></i>Videollamada</span>
                
            <span><i class="fa-sharp fa-solid fa-headset"></i>[+56900000000]</span>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-actions>
</div>
