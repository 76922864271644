import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { register } from 'swiper/element/bundle';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { SettingsService } from "src/app/settings/settings.service";
import { InfoModalOnboarding } from "../info-modal/info-modal";
import { MatTableDataSource } from "@angular/material/table";
import { AgregarFacturas } from "src/app/home/cliente-new-version/agregar-facturas/agregar-facturas";
import { DetalleFacturas } from "src/app/home/cliente-new-version/detalle-facturas/detalle-facturas";
import { AlertDelete } from "src/app/home/cliente-new-version/documentacion/alert-delete/alert-delete";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AddFacturaInfo } from "src/app/home/cliente-new-version/cesion-facturador/add-factura-info/add-factura-info";
import { AgregarCtaComponent } from "./agregar-cta/agregar-cta.component";
import { DeclaracionJuradaComponent } from "./documentos/declaracion-jurada/declaracion-jurada";
import { SituacionFinancieraComponent } from "./documentos/situacion-financiera/situacion-financiera.component";
import { hasData } from "jquery";
import { VisualizarPdfComponent } from "./documentos/visualizar-pdf/visualizar-pdf.component";

register();

declare var $: any;

@Component({
  selector: 'app-simulacion',
  templateUrl: './simulacion.html',
  styleUrls: ['./simulacion.less'],
})
export class SimulacionOnboarding implements OnInit, AfterViewInit {
  business_name:any=localStorage.getItem('business_name');
  id_simulation:any;
  accountData: any;
  hasAccounts: boolean | undefined;
  
  totalDocuments: number = 0;
  completedDocuments: number = 0;
  progress: number = 0;

  company_id = localStorage.getItem('company_id');
  isLoading: boolean = true;
  
  documentStatus: any = {
    declaracionJurada: {
      hasData: false,
      missingFields: 4
    },
    estadoSituacionFinanciera: {
      hasData: false,
      missingFields: 3
    },
    declaracionPep: {
      hasData: false,
    },
    rolAvalista: {
      hasData: false,
    }
  };

  documents = [
    { name: 'Declaración jurada', status: 'falta' },
    { name: 'Declaración PEP', status: 'listo' },
    { name: 'Estado de situación financiera', status: 'falta' },
    { name: 'Rol de avalista', status: 'listo' },
    { name: 'Carta de poder', status: 'listo' },
    { name: 'Poder notarial', status: 'listo' },
    { name: 'Poderes bancarios', status: 'listo' },
    { name: 'Contrato', status: 'listo' }
  ];
showFinancialStatusCard: any;

  constructor(public settings: SettingsService, public dialog: MatDialog, public router:Router, private route:ActivatedRoute, private snackBar:MatSnackBar ) { 
    
    this.route.params.subscribe((params:any) => {
      this.id_simulation = +params['id_simulation'];
    });


  }

  step:any=1;
  id_company=localStorage.getItem('company_id');

  ngOnInit() {
    this.get_simulation();
    this.get_facturas();
    this.load_accounts();
    this.calculateProgress();
  }

  ngAfterViewInit() {
    this.calculateProgress();
  }

  monto_simulado:any=0;
  porcentaje_financiamiento:any=0;
  monto_anticipo:any=0;
  dias_promedio:any=0;
  loading:boolean=false;
  async get_simulation(){
    try {

      this.loading = true;


      const bd: any = await this.settings.get_query(1, `/cliente/simulacion/${this.id_simulation}/`);
      var body_data: any =bd._body;
      switch (bd.status) {
        case 200:


          console.log("simulation info", body_data)

          let sim = body_data.success.info;
          this.monto_simulado = sim.total;
          this.porcentaje_financiamiento = sim.anticipo;
          this.monto_anticipo = sim.valor_anticipo_total;
          this.dias_promedio = sim.dias_promedio;

          this.loading = false;

        break;
        default:

        break;
      }

    } catch (bd:any) {



    }
  }

  detalle_factura(){

    const dialogRef = this.dialog.open(DetalleFacturas,{
      width: '600px',
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.componentInstance.id_sim = this.id_simulation;

  }

  cantidad_facturas:any=0;
  loading_cantidad:boolean=false;
  pageLength = 0;
  pageSize = 7;
  pageIndex = 0;
  page = 1;
  filtro:any="";
  loading_facturas:boolean=false;
  async get_facturas(){


    try {

      this.loading_facturas = true;
      this.facturas = [];

      const bd: any = await this.settings.get_query(1, '/cliente/simulacion-documentos/'+this.id_simulation+'/?page='+this.page+'&filtro='+this.filtro);
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:
 
          this.cantidad_facturas = data.total;
          this.facturas = data.info;
          this.loading_facturas = false;
          
          console.log(this.facturas)

          setTimeout(() => {
            this.dataSource = new MatTableDataSource(this.facturas);
            this.dataSource._updateChangeSubscription();
          });


          


        break;
        }

    } catch (bd:any) {



    }

  }

  applyFilter(){
    this.filtro = this.buscar.value;
    this.get_facturas();
  }

  pageEvent(pageNumber: number): void {
    this.page = pageNumber;
    this.get_facturas();
  }

  open_modal_info(step:any) {


    
    let dialogRef = this.dialog.open(InfoModalOnboarding, {
      width: '700px',
      autoFocus: false,
    });

    dialogRef.componentInstance.step = step;

    dialogRef.afterClosed().subscribe(result => {

      if(result == 'add'){
        //this.open_agregar_facturas();
        this.open_agregar_facturas_dialog();
      }

    });

  }

  home(){
   this.router.navigate(['/home'])
  }

  buscar:any = new FormControl('');
  displayedColumns: string[] = ['deudor', 'factura', 'rut', 'fecha', 'monto', 'accion'];
  ELEMENT_DATA: any[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  facturas:any[]=[];
  comenzar_simulacion:boolean=false;
  open_agregar_facturas(){
    const dialogRef = this.dialog.open(AgregarFacturas,{
      width: '530px',
      maxWidth: '95vw',
      disableClose: true,
    });

    dialogRef.componentInstance.agregar_onboarding = true;
    dialogRef.componentInstance.open_as_dialog = true;

    dialogRef.afterClosed().subscribe(result => {
      if(result.length > 0){
        this.comenzar_simulacion = true;
        this.facturas = result
        console.log(this.facturas)
      }
    });
  }

  async delete_factura(id_factura:any, id_document_simulation:any){

    const elemento = this.facturas.find((el:any) => el.document.document_factura_id === id_factura);
    if (elemento) {
      elemento.isLoading = true;
    }

    try {

      const bd: any = await this.settings.get_query(1, '/cliente/simulacion-validar-delete-folio/'+this.id_simulation+'/?factura='+id_document_simulation, );
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:


        elemento.isLoading = false;

          if(data.info == 3){
            const dialogRef = this.dialog.open(AlertDelete,{
              width: '600px',
              maxWidth: '95vw',
              autoFocus:false,
              disableClose: true,
            });

            dialogRef.componentInstance.id_factura = id_document_simulation;
            dialogRef.componentInstance.id_sim = this.id_simulation;
            dialogRef.componentInstance.titledelete = "¡Estás a punto de eliminar una factura!"
            dialogRef.componentInstance.title = "Si lo haces, será borrada de la"
            dialogRef.componentInstance.subtitle = "operación de forma definitiva."
            dialogRef.componentInstance.deletefacturas = true;
            dialogRef.componentInstance.accion = data.info;

            dialogRef.afterClosed().subscribe((result:any) => {
                if(result == true){
                  this.get_simulation();
                  this.get_facturas();
                }
            });
          }

          if(data.info == 2){
            const dialogRef = this.dialog.open(AlertDelete,{
              width: '600px',
              maxWidth: '95vw',
              autoFocus:false,
              disableClose: true,
            });

            dialogRef.componentInstance.id_factura = id_document_simulation;
            dialogRef.componentInstance.id_sim = this.id_simulation;
            dialogRef.componentInstance.titledelete = "¡Estás a punto de eliminar una factura importante!"
            dialogRef.componentInstance.title = "Si lo haces, el monto total disminuirá por debajo del mínimo"
            dialogRef.componentInstance.subtitle = "necesario para operar ($3.000.000) y la operación será descartada."
            dialogRef.componentInstance.deletefacturas = true;
            dialogRef.componentInstance.accion = data.info;

            dialogRef.afterClosed().subscribe((result:any) => {
              if(result == true){
                this.get_simulation();
                this.get_facturas();
              }
            });
          }

          if(data.info == 1){
            const dialogRef = this.dialog.open(AlertDelete,{
              width: '600px',
              maxWidth: '95vw',
              autoFocus:false,
              disableClose: true,
            });

            dialogRef.componentInstance.id_factura = id_document_simulation;
            dialogRef.componentInstance.id_sim = this.id_simulation;
            dialogRef.componentInstance.titledelete = "¡Estás a punto de eliminar tu única factura!"
            dialogRef.componentInstance.title = "Considera que ya no quedarán facturas "
            dialogRef.componentInstance.subtitle = "y la operación será descartada."
            dialogRef.componentInstance.deletefacturas = true;
            dialogRef.componentInstance.accion = data.info;

            dialogRef.afterClosed().subscribe((result:any) => {
              if(result == true){
                this.get_simulation();
                this.get_facturas();
              }
            });


          }

        break;
        default:

        break;
      }

    } catch (bd:any) {

      switch (bd.status) {
        case 500:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;

        case 400:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:

            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }
  }

  async delete(id_factura:any, accion:any){
    try {
      
      const bd: any = await this.settings.get_query(4, '/cliente/simulacion-documentos/'+id_factura+'/?accion='+accion, );
      var body_data: any =bd._body;
      var data = body_data.success;
      switch (bd.status) {
        case 200:




        break;
        default:

        break;
      }

    } catch (bd:any) {


    }
  }

  open_agregar_facturas_dialog(){
    const dialogRef = this.dialog.open(AddFacturaInfo,{
      width: '600px',
      maxWidth: '95vw',
      autoFocus:false,
    });

    dialogRef.componentInstance.parent = this;
    dialogRef.componentInstance.id_sim = this.id_simulation;
    dialogRef.componentInstance.step = 2;
    dialogRef.componentInstance.onboarding = true;

    dialogRef.afterClosed().subscribe((result:any) => {

      if(result == true){
        this.get_facturas();
        this.get_simulation();
      }

    });
  }

  loading_pdf_:boolean=false;
  async download_pdf(){
    try {
      
      this.loading_pdf_ = true;

      const bd: any = await this.settings.get_query(1, '/cliente/simulacion-detalle-pdf/'+this.id_simulation+'/', );
      var body_data: any =bd._body;
      var data = body_data;
      switch (bd.status) {
        case 200:

          window.open(data.respuesta.info, "__blank");
          this.loading_pdf_ = false;

        break;
        default:

        break;
      }

    } catch (bd:any) {

      console.log(bd)

      switch (bd.status) {
        case 500:
            this.loading_pdf_ = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

          break;

        case 400: 
        this.loading_pdf_ = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });
          break;
        default:
          this.loading_pdf_ = false;
            this.snackBar.open('Ocurrió un error, intenta de nuevo', 'Aceptar', {
              duration: 20000,
              panelClass: 'simpli-snack'
            });

        break;
      }
    }
  }

  //Codigo de cuentas bancarias
  stepper_cuentas:any=1;
  isDragOver1 = false;
  isDragOver2 = false;
  uploadedFile1: File | null = null;
  uploadedFile2: File | null = null;
  onDragOver(event: DragEvent, zone: number): void {
    event.preventDefault();
    if (zone === 1) {
      this.isDragOver1 = true;
    } else {
      this.isDragOver2 = true;
    }
  }

  onDragLeave(zone: number): void {
    if (zone === 1) {
      this.isDragOver1 = false;
    } else {
      this.isDragOver2 = false;
    }
  }

  onFileDrop(event: DragEvent, zone: number): void {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files?.length) {
      this.assignFile(files[0], zone);
    }
    this.onDragLeave(zone);
  }

  onFileSelect(event: Event, zone: number): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      this.assignFile(input.files[0], zone);
    }
  }

  triggerFileInput(zone: number): void {
    const fileInput = document.getElementById(`fileInput${zone}`) as HTMLElement;
    fileInput.click();
  }

  assignFile(file: File, zone: number): void {
    if (zone === 1) {
      this.uploadedFile1 = file;
    } else if (zone === 2) {
      this.uploadedFile2 = file;
    }
  }

  subir_archivos(){
    console.log(this.uploadedFile1)
    console.log(this.uploadedFile2)
  }


  async load_accounts() {
    try {
      const bd: any = await this.settings.get_query(1, '/cuentasAcreedoresBank/' + this.id_company + '/?filtro=' + this.filtro);
      var body_data: any = bd._body;
      this.accountData = body_data.accounts && body_data.accounts.length > 0 ? body_data.accounts[0] : null;
      this.hasAccounts = body_data.accounts && body_data.accounts.length > 0;
      console.log('Account Data:', this.accountData);
      
    } catch (bd:any) {
      this.accountData = null; 
      this.hasAccounts = false;
    }
  }
  
  agregarCuentaBancaria(){
    const dialogRef = this.dialog.open(AgregarCtaComponent,{
      width: '500px',
      maxWidth: '95vw',
      autoFocus:false,
      height: 'auto',
      maxHeight: '95vh',
    });
    
  }

  editarCuentaBancaria(id: any) {
    console.log('Opening dialog with id:', id);
    const dialogRef = this.dialog.open(AgregarCtaComponent, {
      width: '500px',
      maxWidth: '95vw',
      autoFocus: false,
      height: 'auto',
      maxHeight: '95vh',
      data: this.accountData
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog closed');
    });
  }

  capitalizeText(text: string): string {
    return text.toLowerCase().split(' ').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
  }
  
  calculateProgress() {
    this.totalDocuments = this.documents.length;
    this.completedDocuments = this.documents.filter(doc => doc.status === 'listo').length;
    this.progress = Math.round((this.completedDocuments / this.totalDocuments) * 100);
  }

  hasPendingDocuments(): boolean {
    return this.documents.some(doc => doc.status === 'falta');
  }

  handleDocumentClick(fullDocumentName: string, action: string, event: Event): void {
    event.stopPropagation();

    if (action === 'view') {
      this.openDocumentDialog(fullDocumentName);
    } else if (action === 'download') {
      this.downloadDocument(fullDocumentName);
    }
  }

  openDocumentDialog(documentType: string) {
    let componentToOpen: any;

    switch (documentType) {
      case 'Declaración jurada':
        componentToOpen = DeclaracionJuradaComponent;
        break;
      case 'Declaración PEP':
        componentToOpen = VisualizarPdfComponent;
        break;
      case 'Estado de situación financiera':
        componentToOpen = SituacionFinancieraComponent;
        break;
      default:
        console.error('Tipo de documento desconocido:', documentType);
        return;
    }

    const dialogRef = this.dialog.open(componentToOpen, {
      width: '600px',
      maxWidth: '95vw',
      autoFocus: false,
      height: 'auto',
      maxHeight: '95vh',
      data: {
        documentType: documentType,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDocumentos();
    });

    console.log('Documento abierto:', documentType);
  }


  async downloadDocument(documentType: string) {
    if (documentType === 'Declaración PEP' && this.documentStatus.declaracionPep.hasData) {
      const response: any = await this.settings.get_query(1, `/onboarding/data-onboarding/${this.company_id}/`);
      const pepLink = response._body.success.info.set_documentos.set1.declaracion_pep?.link;
    
      if (pepLink) {
        window.open(pepLink, '_blank');
      }
    }
    console.log(`Descargando documento de tipo: ${documentType}`);
  }


  dialogSituacionFinanciera(): void {
    this.stepper_cuentas = 2;
    this.step = 4;

    this.handleDocumentClick('Estado de situación financiera', 'view', new Event('click'));
  }

  verDocumentos() {
    this.stepper_cuentas = 2;
    this.step = 4;
    this.getDocumentos();
  }
  

  async getDocumentos() {
    this.isLoading = true;
    try {
      const response: any = await this.settings.get_query(1, `/onboarding/data-onboarding/${this.company_id}/`);
      const set1 = response._body.success.info.set_documentos.set1;

      this.statusDeclaracionJurada(set1.declaracion_jurada);
      this.statusSituacionFinanciera(set1.informacion_financiera);

      const declaracionPep = set1.declaracion_pep?.link;
      this.documentStatus.declaracionPep = {
        hasData: !!declaracionPep
      }

     const rolAvalista = set1.rol_avalista?.link;
     this.documentStatus.rolAvalista = {
        hasData: !!rolAvalista
      }

    } catch (error) {
      console.error('Error al obtener datos declaracion jurada:', error); 
    } finally {
      this.isLoading = false;
    }
  }

  statusDeclaracionJurada(declaracionJurada: any) {
    let missingCount = 0;
    const sections = ['antecedentes_declarante', 'beneficiarios_finales', 'control_efectivo', 'persona_natural'];

    // Antecedentes declarante
    const hasAntecedentes = !!(
      declaracionJurada.antecedentes_declarante?.domicilio?.direccion &&
      declaracionJurada.antecedentes_declarante?.domicilio?.comuna &&
      declaracionJurada.antecedentes_declarante?.domicilio?.ciudad &&
      declaracionJurada.antecedentes_declarante?.telefono
    );
    if (!hasAntecedentes) missingCount++;

    // Beneficiarios finales
    const hasBeneficiarios = !!(declaracionJurada.beneficiarios_finales?.length);
    if (!hasBeneficiarios) missingCount++;

    // Control efectivo
    const hasControl = !!(declaracionJurada.control_efectivo?.length);
    if (!hasControl) missingCount++;

    // Persona natural
    const hasPersonaNatural = !!(declaracionJurada.persona_natural?.relacion_pj_declarante);
    if (!hasPersonaNatural) missingCount++;

    this.documentStatus.declaracionJurada = {
        hasData: missingCount === 0,
        missingFields: missingCount
    };
  }

  statusSituacionFinanciera(situacionFinanciera: any) {
    let missingCount = 0;
    const sections = ['antecedentes_personales', 'antecedentes_conyuge', 'antecedentes_comerciales'];

    const hasAntecedentesPersonales = !!(
      situacionFinanciera.antecedentes_personales?.direccion && 
      situacionFinanciera.antecedentes_personales?.comuna && 
      situacionFinanciera.antecedentes_personales?.ciudad && 
      situacionFinanciera.antecedentes_personales?.telefono && 
      situacionFinanciera.antecedentes_personales?.profesion 
    );
    if (!hasAntecedentesPersonales) missingCount++;
    console.log('Antecedentes personales:', hasAntecedentesPersonales ? 'Completo' : 'Incompleto');


    const hasAntecedentestecedentes = !!(
      situacionFinanciera.antecedentes_conyuge?.nacionalidad &&
      situacionFinanciera.antecedentes_conyuge?.profesion &&
      situacionFinanciera.antecedentes_conyuge?.empleador 
    );
    if (!hasAntecedentestecedentes) missingCount++;
    console.log('Antecedentes conyuge:', hasAntecedentestecedentes ? 'Completo' : 'Incompleto');


    const hasAntecedentesComerciales = !!(situacionFinanciera.antecedentes_comerciales?.length);
    if (!hasAntecedentesComerciales) missingCount++;
    console.log('Antecedentes comerciales:', hasAntecedentesComerciales ? 'Completo' : 'Incompleto');

    this.documentStatus.estadoSituacionFinanciera = {
      hasData: missingCount === 0,
      missingFields: missingCount
    };
  
  }

  
}