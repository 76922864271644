<div mat-dialog-title>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn"
    style="position: relative;margin-top: 10px;">
    <div class="close-dialog" mat-dialog-close>
      <i class="fa-regular fa-xmark"></i>
    </div>
    <div class="titulo-onboarding">revisa el Poder simple</div>
    <div class="subtitulo-onboarding">Si está todo en orden, firma el documento.</div>

  </div>

</div>

<mat-dialog-content>

  <div class="row">
    <iframe [src]="pdfUrl + '#navpanes=0&scrollbar=0&view=Fit&toolbar=0' | safe" width="100%" height="500px" frameBorder="0" style="cursor: default;"></iframe>
  </div>

</mat-dialog-content>

<mat-dialog-actions style="margin-bottom: 20px; display: flex; flex-direction: column; align-items: stretch;">

  <div style="display: flex; justify-content: center;">
    <button  type="button" 
      mat-stroked-button class="primary-button" color="primary">
      Firmar Poder simple
    </button>
  </div>

  <div style="display: flex; flex-direction: column;">
    <div style="display: flex; justify-content: center; align-items: center;">
      <div class="contact-container">
        <div>Si necesitas actualizar los datos, contacta a un ejecutivo.</div>
        <div class="icons">
          <span (click)="agendar()"><i class="fa-light fa-video"></i>Videollamada</span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span><i class="fa-sharp fa-solid fa-headset"></i>[+56900000000]</span>
        </div>
      </div>
    </div>
  </div>
  
</mat-dialog-actions>