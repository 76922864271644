import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AgendarOnboarding } from 'src/app/onboarding/agendar/agendar';

@Component({
  selector: 'app-poder-simple',
  templateUrl: './poder-simple.component.html',
  styleUrl: './poder-simple.component.less',
})
export class PoderSimpleComponent {
  constructor(public dialog: MatDialog) {}

  agendar() {
    let dialogRef = this.dialog.open(AgendarOnboarding, {
      width: '700px',
      minHeight: '600px',
      autoFocus: false,
    });
  }

  // pdfUrl: string | null = null;

  pdfUrl: string = 'https://drive.google.com/file/d/1I82htebbGNBG48ky-IplhjiTHq8hD52x/preview';
  
}
