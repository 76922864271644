<div mat-dialog-title>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__fadeIn"
    style="position: relative;margin-top: 10px;">
    <div class="close-dialog" mat-dialog-close>
      <i class="fa-regular fa-xmark"></i>
    </div>
    <div class="titulo-onboarding">ingresa los datos de la cuenta </div>
    <div class="subtitulo-onboarding">Para registrar una cuenta de terceros, tendrás que firmar un poder simple.</div>

  </div>

</div>

<mat-dialog-content>

  <div class="row">
    <form [formGroup]="valForm" (submit)="submitForm($event, valForm.value)">

      <mat-form-field [appearance]="formAppearance">
        <mat-label>Selecciona un banco</mat-label>
        <input type="text" (change)="validate_error($event)" required formControlName="bank" matInput
          [matAutocomplete]="auto" />
        <mat-icon matSuffix>arrow_drop_down</mat-icon>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="filtrar_banco($event.option)">
          <mat-option data-id="{{option.id}}" *ngFor="let option of filteredOptions | async" [value]="option.name">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>



      <mat-form-field [appearance]="formAppearance">
        <mat-label>Tipo de Cuenta</mat-label>
        <mat-select formControlName="type" required>
          <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.id">
            {{cuenta.name}}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="valForm.get('type')?.hasError('required') && (valForm.get('type')?.dirty || valForm.get('type')?.touched)">
          El tipo de cuenta es <strong>requerido</strong>
        </mat-error>
      </mat-form-field>


      <mat-form-field [appearance]="formAppearance">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="name" required>
        <mat-error
          *ngIf="valForm.get('name')?.hasError('required') && (valForm.get('name')?.dirty || valForm.get('name')?.touched)">
          El nombre es <strong>requerido</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field [appearance]="formAppearance">
        <mat-label>Cuenta</mat-label>
        <input matInput (keyup)="transform_number()" formControlName="number" type="text" required>
        <mat-error
          *ngIf="valForm.get('number')?.hasError('required') && (valForm.get('number')?.dirty || valForm.get('number')?.touched)">
          El número de cuenta es <strong>requerido</strong>
        </mat-error>
        <mat-error
          *ngIf="valForm.get('number')?.hasError('pattern') && (valForm.get('number')?.dirty || valForm.get('number')?.touched)">
          El número de cuenta debe ser numerico y contener de 6 a 12 digitos
        </mat-error>
      </mat-form-field>


      <mat-form-field [appearance]="formAppearance">
        <mat-label>RUT</mat-label>
        <input matInput formControlName="rut" [(ngModel)]="run" (keyup)="change_run()" style="text-transform: uppercase"
          required>
        <mat-error
          *ngIf="valForm.get('rut')?.hasError('required') && (valForm.get('rut')?.dirty || valForm.get('rut')?.touched)">
          El RUT es <strong>requerido</strong>
        </mat-error>
        <mat-error
          *ngIf="valForm.get('rut')?.hasError('pattern') && (valForm.get('rut')?.dirty || valForm.get('rut')?.touched)">
          El RUT debe ser en formato DDDDDDD-D Ejm: 17983599-8
        </mat-error>
      </mat-form-field>

      <mat-form-field [appearance]="formAppearance">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" required>
        <mat-error
          *ngIf="valForm.get('email')?.hasError('required') && (valForm.get('email')?.dirty || valForm.get('email')?.touched)">
          El email es <strong>requerido</strong>
        </mat-error>
        <mat-error
          *ngIf="valForm.get('email')?.hasError('pattern') && (valForm.get('email')?.dirty || valForm.get('email')?.touched)">
          Ingrese un email válido
        </mat-error>
      </mat-form-field>


      <!-- <mat-slide-toggle (toggleChange)="change_toggle_custom($event)" (change)="change_toggle($event)"
        [disabled]="disableTerceroBtn" color="primary" formControlName="tercero"
        style="margin-top: 10px;margin-bottom:10px; font-size: 12px;font-size: DM Sans;color:gray;"> Cuenta de
        terceros</mat-slide-toggle>

      <button *ngIf="is_tercero" type="button" (click)="fileInput.click()" class="primary-button"
        style="margin-top: 30px;text-align: center;" mat-stroked-button color="primary"
        aria-label="Agregar archivos de tercero"><i class="fa-solid fa-paperclip"></i></button>

      <input hidden formControlName="file" type="file" id="file" #fileInput (change)="onFileChange($event)" />
      <div *ngIf="is_tercero" class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-top">
        <mat-form-field [appearance]="formAppearance">
          <input class="no-input" readonly (click)="fileInput.click()" matInput formControlName="placeholderFile"
            placeholder="Carga el archivo de terceros" [(ngModel)]="placeholder">
          <mat-error
            *ngIf="valForm.get('placeholderFile')?.hasError('required') && (valForm.get('placeholderFile')?.dirty || valForm.get('placeholderFile')?.touched)">
            El archivo de terceros es requerido
          </mat-error>
        </mat-form-field>
      </div> -->

    </form>
  </div>

</mat-dialog-content>

<mat-dialog-actions style="margin-bottom: 20px; display: flex; flex-direction: column; align-items: stretch;">
  <div style="display: flex; flex-direction: column;">
    <div style="display: flex; justify-content: flex-start; align-items: center;">
      <mat-checkbox (change)="change_toggle($event)" [checked]="is_tercero"
      [disabled]="(companyRun?.toUpperCase() == run?.toUpperCase() && run?.length > 8) || (companyRun?.toUpperCase() != run?.toUpperCase() && run?.length > 8)">
        Registrar como cuenta de terceros
      </mat-checkbox>

      <span style="display: flex; align-items: center; margin-left: 10px; font-size: 20px;"
        matTooltip="Son cuentas bancarias que no están asociadas al nombre y RUT de la empresa (personas naturales, proveedores, otras entidades, etc)"
        matTooltipPosition="left" matTooltipClass="is-tercero">
        <i class="fa-sharp fa-regular fa-circle-info" style="color: #5B61EF;"></i>
      </span>
    </div>

    <div class="isTercero" *ngIf="is_tercero || valForm.get('tercero')?.value" style="margin-top: 10px;">
      <div class="titulo-tercero">firma el Poder simple para cuentas de terceros </div>
      <div class="subtitulo-tercero" style="margin-top: 10px;">Debes firmar el Poder para autorizarnos a girar el financiamiento a la cuenta de terceros.</div>

      <div class="container-cuenta" (click)="poderSimple()">
        <div>Poder simple</div>
        <div class="status-cuenta" style="font-size: 20px;"><i class="fa-light fa-eye"></i></div>
      </div>

    </div>
  </div>
  <div style="display: flex; justify-content: center; margin-top: 16px;">
    <button [disabled]="!valForm.valid || disabledButton" type="button" (click)="submitForm($event, valForm.value)"
      mat-stroked-button class="primary-button" color="primary">
      Registrar cuenta
    </button>
  </div>
</mat-dialog-actions>