import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { SettingsService } from 'src/app/settings/settings.service';
import { map, startWith } from 'rxjs/operators';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PoderSimpleComponent } from './poder-simple/poder-simple.component';


export interface Banco {
  id: string;
  name: string;
}

export interface Cuenta {
  id: string;
  name: string;
}

@Component({
  selector: 'app-agregar-cta',
  templateUrl: './agregar-cta.component.html',
  styleUrl: './agregar-cta.component.less',
})
export class AgregarCtaComponent implements OnInit {
  valForm: FormGroup;

  run: any;
  full_name: any;
  dialog_open: boolean = false;
  parent: any;

  disabledButton: boolean = false;
  showSpinner: boolean = false;

  bancos: Banco[] = [];

  cuentas: Cuenta[] = [];

  is_tercero: boolean = false;
  placeholder!: string;
  email: any;

  file: any;
  //autocomplete select
  myControl = new FormControl();
  filteredOptions: Observable<Banco[]> | undefined;
  bank_selected: any;

  id_company = localStorage.getItem('company_id');
  companyRun = localStorage.getItem('business_name');

  @Input() accountData: any;
  id_cuenta: any;
  formAppearance: 'fill' | 'outline' = 'fill';



  constructor(
    fb: FormBuilder,
    public snackBar: MatSnackBar,
    public settings: SettingsService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    let rutValid = new FormControl(
      null,
      Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{7,10}[-][0-9kK]$'),
      ])
    );
    let accNumber = new FormControl(
      null,
      Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{4,12}$'),
      ])
    );

    this.valForm = fb.group({
      bank: ['', Validators.compose([Validators.required])],
      type: ['', Validators.compose([Validators.required])],
      rut: rutValid,
      number: accNumber,
      name: ['', Validators.compose([Validators.required])],
      tercero: new FormControl(''),
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')
      ])],
      file: [''],
      placeholderFile: new FormControl(''),
    });

    this.valForm.controls['tercero'].setValue(false);

     if (this.data?.bank?.name) {
        this.valForm.patchValue({
            bank: this.data.bank.name,
            type: this.data.type?.id,
            name: this.data.name,
            number: this.data.number,
            rut: this.data.rut,
            email: this.data.email || '',
            tercero: this.data.is_tercero
        });
        this.formAppearance = 'outline';
        this.bank_selected = this.data.bank.id;
        this.run = this.data.rut;
        this.is_tercero = this.data.is_tercero;
    }
  }

  async submitForm($ev: any, value: any) {
    $ev.preventDefault();

    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }

    if (this.valForm.valid) {
      this.disabledButton = true;
      this.showSpinner = true;
      const formData = new FormData();

      if (this.file) {
        formData.append('file', this.file); // Ensure this.file is not null
      }
      //else {
      //   console.error('File not selected');
      //   this.disabledButton = false;
      //   this.showSpinner = false;
      //   return;
      // }

      // Append other form data to FormData object
      formData.append('bank', this.bank_selected);
      formData.append('email', this.cleanEmail(value.email));
      for (let key in value) {
        if (key !== 'file' && key !== 'bank' && key !== 'email') {
          formData.append(key, value[key]);
        }
      }

      try {
        const bd: any = await this.settings.get_query(
          2,
          '/cuentasAcreedoresBank/' + this.id_company + '/',
          formData
        );
        var body_data: any = bd._body;
        switch (bd.status) {
          case 201:
          case 200: {
            this.disabledButton = false;
            this.showSpinner = false;

            let sb = this.snackBar.open(
              `¡Cuenta agregada con exito! comentario IA: ${body_data.analisis_gpt}`,
              'Aceptar',
              {
                duration: 20000,
                panelClass: 'simpli-snack',
              }
            );
            this.is_tercero = false;
            this.valForm.get('placeholderFile')?.clearValidators();
            this.valForm.get('placeholderFile')?.updateValueAndValidity();

            if (this.dialog_open == true) {
              this.parent.post_facturas();
            }
            // } else {
            //   this.funciones.emit(1);
            // }

            this.ngOnInit();
            this.valForm.reset();

            break;
          }
          default: {
            break;
          }
        }
      } catch (bd: any) {}
    }
  }

  toggleTerceroValidation() {
    if (this.is_tercero == true) {
      this.valForm.get('placeholderFile')?.setValidators([Validators.required]);
    } else {
      this.valForm.get('placeholderFile')?.clearValidators();
    }
    this.valForm.get('placeholderFile')?.updateValueAndValidity();
  }

  validate_error($event: any) {
    if (this.bank_selected == undefined) {
      this.valForm.controls['bank'].setValue('');
      this.valForm.controls['bank'].setErrors({ required: true });
    }
  }

  filtrar_banco($e: any) {
    this.bank_selected = $e.id;
    let cuenta = { id: '2', name: 'Cuenta RUT' };
    if (this.bank_selected != '26') {
      if (this.cuentas.indexOf(cuenta) != -1) {
        this.cuentas.splice(this.cuentas.indexOf(cuenta), 1);
      }
    } else {
      this.cuentas.push(cuenta);
    }
  }

  transform_number() {
    let regex = /[^0-9]+/;
    let phone = String(this.valForm.controls['number'].value).replace(
      regex,
      ''
    );
    if (phone != '') {
      return this.valForm.controls['number'].setValue(phone);
    } else {
      this.valForm.controls['number'].reset();
    }
  }

  ngOnInit() {
    this.get_personal_data();
    this.load_bancos();
    this.load_cuentas();
    // this.valForm.get('placeholderFile')?.disable()
  }

  async load_bancos() {
    try {
      const bd: any = await this.settings.get_query(1, '/bank/');
      var body_data: any = bd._body;
      switch (bd.status) {
        case 201:
        case 200:
          let body_data = bd._body;

          for (let i = 0; i < body_data.banks.length; i++) {
            this.bancos.push(body_data.banks[i]);
          }

          this.filteredOptions = this.valForm.controls[
            'bank'
          ].valueChanges.pipe(
            startWith(''),
            map((value: { name: any }) =>
              typeof value === 'string' ? value : value.name
            ),
            map((name: string) =>
              name ? this._filter(name) : this.bancos.slice()
            )
          );

          break;
        default:
          break;
      }
    } catch (bd: any) {}
  }

  private _filter(name: string): Banco[] {
    const filterValue = name.toLowerCase();

    return this.bancos.filter(
      (option) => option.name.toLowerCase().includes(filterValue) === true
    );
  }
  async load_cuentas() {
    try {
      const bd: any = await this.settings.get_query(1, '/account-type/');
      var body_data: any = bd._body;
      switch (bd.status) {
        case 201:
        case 200:
          var body_data: any = bd._body;

          for (let i = 0; i < body_data.accounts.length; i++) {
            if (i != 1) {
              this.cuentas.push(body_data.accounts[i]);
            }
          }

          break;
        default:
          break;
      }
    } catch (bd: any) {}
  }

  async get_personal_data() {
    try {
      const bd: any = await this.settings.get_query(
        1,
        '/company/' + this.id_company + '/'
      );
      var body_data: any = bd._body;
      switch (bd.status) {
        case 201:
        case 200:
          var body_data: any = bd._body;
          this.full_name = body_data.company.business_name;

          // this.valForm.controls['rut'].setValue(body_data.company.rut);
          // this.valForm.controls['name'].setValue(this.full_name);
          // this.run = body_data.company.rut;

          this.companyRun = body_data.company.rut;
          break;
        default:
          break;
      }
    } catch (bd: any) {}
  }
  disableTerceroBtn: boolean = false;
  change_run() {
    let st = this.run.replace('-', '');
    let arr = st.split('');
    let st_out = '';

    for (let i = 0; i < arr.length; i++) {
      st_out = st_out + arr[i];
      if (i == arr.length - 2) {
        st_out = st_out + '-';
      }
    }

    if(this.companyRun?.toUpperCase() != st_out.toUpperCase() && st_out.length > 8) {
      this.valForm.get('tercero')?.setValue(true);
      this.is_tercero = true;
    } else {
      this.valForm.get('tercero')?.setValue(false);
      this.is_tercero = false;
    }
  
    this.run = st_out;
  }

  change_toggle($event: any) {
    console.log($event.checked);
    if ($event.checked == true) {
      this.is_tercero = true;
      this.toggleTerceroValidation();
    } else {
      this.is_tercero = false;
      this.toggleTerceroValidation();
    }
  }

  change_toggle_custom($event: any) {
    console.log($event);
  }

  cleanEmail(email: string): string {
    return email.replace(/\s+/g, '');
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      console.log('entre', this.file);
      this.placeholder = this.file.name;
    }
  }

  poderSimple(){
    const dialogRef = this.dialog.open(PoderSimpleComponent, {
      width: '500px',
      maxWidth: '95vw',
      autoFocus:false,
      height: 'auto',
      maxHeight: '95vh',
      data: {
        formValues: this.valForm.value,
      }
    });
    
  }
}
