<div [ngClass]="{'row':true, 'row_main': step != 2}">

  <!--detalle simulación-->
  @if(step == 1){
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">


    <div style="margin-top: 30px;">
      <div class="titulo-card" style="margin-bottom: 10px;">¡revisa la simulación de tu primera operación en Simpli!
      </div>
      <div class="label-container" style="text-align: center;">
        Las facturas que elegimos están <span (click)="open_modal_info(10)">preaprobadas</span> para una operación
        inmediata.
      </div>
    </div>



  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">


    <mat-card class="centerabsolute" style="max-width: 600px;">

      <mat-card-content>

        <div class="box-info-onboarding">
          <div class="icon"><i class="fa-sharp fa-solid fa-circle-info"></i></div>
          <div>La primera <span (click)="open_modal_info(6)">operación de factoring</span> tiene un costo de apertura de
            $98.500, que puedes pagar en 2 cuotas.</div>
        </div>

        <div class="box-info-facturas">
          <div class="titulo-facturas">Facturas de la simulación</div>

          <div class="container-facturas add_factoring">

            <div class="row" style="margin-bottom: 5px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Cantidad de facturas:</div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" style="font-weight: bold;text-align: right;">
                @if(loading_cantidad){
                <skeleton-loader></skeleton-loader>
                }@else{
                {{cantidad_facturas}}
                }
              </div>

            </div>

            <div>
              <div style="text-align: right;">
                <mat-label class="pink-label" (click)="detalle_factura()">Abrir detalle <i
                    class="fa-solid fa-angle-right"></i></mat-label>
              </div>
            </div>

          </div>
        </div>

        <div class="box-info-facturas">
          <div class="titulo-facturas">Montos asociados</div>

          <div>
            <div class="row" style="margin-bottom: 5px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Monto total simulado:</div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"
                style="text-align: right;font-weight: bold;padding-right: 40px;">
                @if(loading){
                <skeleton-loader></skeleton-loader>
                }@else{
                ${{settings.formatMoney(monto_simulado)}}
                }
              </div>
            </div>
            <div class="row" style="margin-bottom: 5px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Porcentaje de financiamiento:</div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"
                style="text-align: right;font-weight: bold;padding-right: 40px;position: relative;">
                <span class="edit"><i class="fa-solid fa-pencil"></i></span>
                @if(loading){
                <skeleton-loader></skeleton-loader>
                }@else{
                {{porcentaje_financiamiento}}%
                }
              </div>
            </div>
            <div class="row" style="margin-bottom: 5px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Monto de anticipo:</div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"
                style="text-align: right;font-weight: bold;padding-right: 40px;">
                @if(loading){
                <skeleton-loader></skeleton-loader>
                }@else{
                ${{settings.formatMoney(monto_anticipo)}}
                }
              </div>
            </div>
            <div class="row" style="margin-bottom: 5px;">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">Vencimiento:</div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"
                style="text-align: right;font-weight: bold;padding-right: 40px;position: relative;">
                <span class="edit"><i class="fa-solid fa-pencil"></i></span>
                @if(loading){
                <skeleton-loader></skeleton-loader>
                }@else{
                {{dias_promedio}} días
                }
              </div>
            </div>
          </div>

        </div>

        <div class="contact-container" style="margin-top: 10px;">
          <div class="icons">
            <span (click)="step = 3"><i class="fa-sharp fa-light fa-pencil"></i>Editar simulación</span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            @if(loading_pdf_ == false){
            <span (click)="download_pdf()"><i class="fa-sharp fa-light fa-download"></i>Descargar simulación</span>
            }@else{
            <span style="opacity: 0.5;"><i class="fa-solid fa-circle-notch fa-spin"></i> Descagar simulación</span>
            }

          </div>
        </div>

        <div class="caja-botones">
          <button mat-stroked-button class="primary-button" (click)="step = 2">Continuar</button>
        </div>

      </mat-card-content>

    </mat-card>



  </div>
  }


  <!--simulacion ok-->
  @if(step == 2){
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">


    <mat-card class="centerabsolute2" style="max-width: 700px;">
      <div class="titulo-card">Solo falta que formalices tu incorporación como cliente</div>
      <mat-card-content>

        <div class="container-final" style="flex-direction: column;">

          <div class="imagen-final">
            <img src="../../assets/iconografia/formalizar.png" style="height: 200px !important;">
          </div>

          <div class="container-titulos" style="margin-top: 20px;margin-bottom: 10px;">
            <div class="label-container">
              Para seguir adelante con el financiamiento de tu<br>operación, debes <strong>formalizar tu
                incorporación.</strong>

              <br><br>
              En breve, <strong>Roger Perdomo te contactará</strong><br>para guiarte en este proceso.
            </div>
          </div>

          <div class="caja-botones">
            <button mat-stroked-button class="secondary-button" (click)="step = 1">Volver a revisar simulación</button>
            <button mat-stroked-button class="primary-button" (click)="step = 4; stepper_cuentas = 1">Continuar</button>
          </div>

        </div>

      </mat-card-content>
    </mat-card>



  </div>
  }

  <!-- dialog estado situacion financiera -->
  @if(step == 5){
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">
    <mat-card class="centerabsolute2" style="max-width: 700px;">
      <div style="position: relative;margin-top: 10px;">
        <div class="volver-dialog" (click)="stepper_cuentas = 2; step = 4">
          <i class="fa-solid fa-chevron-left"></i>
          <span>Volver</span>
        </div>
      </div>
      <div class="imagen-final">
        <img src="../../assets/iconografia/iconografia - web analysis.png" style="height: 200px !important;">
      </div>
      <mat-card-content>
        <div class="container-final" style="flex-direction: column;">
          <div class="titulo-card situacion-fin">¿Cuentas con un Estado de situación utilizado dentro de los <br>últimos
            6 meses en otra
            entidad?
          </div>
          <div class="caja-botones-situacion-fin">
            <button mat-stroked-button class="secondary-button" (click)="dialogSituacionFinanciera()">No</button>
            <button mat-stroked-button class="primary-button" (click)="step = 4">Sí</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  }

  <!--editar simulacion-->
  @if(step == 3){
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">


    <div style="margin-top: 30px;">
      <div class="titulo-card" style="margin-bottom: 10px;">¡revisa las facturas que seleccionamos para tu simulación!
      </div>
      <div class="label-container" style="text-align: center;">
        Las siguientes facturas <strong>fueron evaluadas</strong> y ya cuentan con nuestra <span
          (click)="open_modal_info(10)">preaprobación</span>.
      </div>
    </div>



  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12"></div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">

        <div style="display: flex;align-items: center;">
          <mat-form-field appearance="fill" style="width: 400px;">
            <mat-label>Buscar</mat-label>
            <input matInput [formControl]="buscar" (change)="applyFilter()">
            <mat-icon matSuffix class="icon-suffix">search</mat-icon>
          </mat-form-field>

          <button style="margin-left: 20px;" (click)="open_modal_info(11)" mat-stroked-button
            class="secondary-button">Agregar más facturas</button>
        </div>

      </div>
    </div>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">

    <div class="row">

      @if(facturas.length > 0 && loading_facturas == false){
      <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12" style="margin: 0 auto;">
        <div class="label-table">Puedes eliminar o agregar más facturas antes de operar.</div>

        <mat-card class="card-tabla">
          <mat-card-content>
            <div class="table-responsive-simpli add_factoring">

              <table mat-table [dataSource]="dataSource">


                <ng-container matColumnDef="deudor">
                  <th mat-header-cell *matHeaderCellDef>Deudor</th>
                  <td mat-cell *matCellDef="let element">
                    <input readonly class="no-input" value="{{element.document.nombre_deudor}}">
                  </td>
                </ng-container>

                <ng-container matColumnDef="factura">
                  <th mat-header-cell *matHeaderCellDef>Factura</th>
                  <td mat-cell *matCellDef="let element">
                    <input readonly class="no-input" value="{{element.document.folio}}">
                  </td>
                </ng-container>

                <ng-container matColumnDef="rut">
                  <th mat-header-cell *matHeaderCellDef>RUT</th>
                  <td mat-cell *matCellDef="let element">
                    <input readonly class="no-input" value="{{element.document.rut_deudor}}">
                  </td>
                </ng-container>

                <ng-container matColumnDef="fecha">
                  <th mat-header-cell *matHeaderCellDef>Fecha de emisión</th>
                  <td mat-cell *matCellDef="let element">
                    <input readonly class="no-input" value="{{element.document.fecha_documento}}">
                  </td>
                </ng-container>

                <ng-container matColumnDef="monto">
                  <th mat-header-cell *matHeaderCellDef>Monto</th>
                  <td mat-cell *matCellDef="let element">
                    <input readonly class="no-input" value="${{settings.formatMoney(element.document.monto_total)}}">
                  </td>
                </ng-container>

                <ng-container matColumnDef="accion">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element" style="cursor: pointer;">
                    <span *ngIf="!element.isLoading"
                      (click)="delete_factura(element.document.document_factura_id, element.document_simulacion_id)"><i
                        class="fa-regular fa-trash-can"></i></span>
                    <span *ngIf="element.isLoading"><i class="fa-solid fa-circle-notch fa-spin"></i></span>
                  </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>

            </div>
          </mat-card-content>
        </mat-card>

        <div class="caja-botones" style="text-align: right;">
          <button mat-stroked-button class="primary-button" (click)="step = 1">Volver</button>
        </div>


      </div>
      }

      @if(facturas.length == 0 && loading_facturas == true){
      <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12" style="margin: 0 auto;">
        <loading-interno></loading-interno>
      </div>
      }

      @if(loading_facturas == false){
      <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12" style="margin: 0 auto;">
        <paginador-onboarding [pageSize]="7" [totalElements]="cantidad_facturas" [currentPage]="page"
          (pageChange)="pageEvent($event)">
        </paginador-onboarding>
      </div>
      }

      @if(facturas.length == 0 && loading_facturas == false){
      <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12" style="margin: 0 auto;margin-bottom: 20px;margin-top: 20px;">
        <no-hay-registros [text]="'No se encontraron facturas.'"></no-hay-registros>
      </div>
      }

    </div>
  </div>
  }

  <!-- 4 cuentas bancarias-->
  @if(step == 4){

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">

    <div class="title-container" style="text-align: center;">
      <div class="titulo-card">formaliza tu incorporación como cliente</div>
    </div>


    <div class="stepper">
      <div [ngClass]="{'step': true, 'active': stepper_cuentas == 1}">
        <div class="circle">
          <i class="fa-light fa-building-columns"></i>
        </div>
        <div class="step-text">Cuenta bancaria</div>
      </div>
      <div class="line"></div>
      <div [ngClass]="{'step': true, 'active': stepper_cuentas == 2}">
        <div class="circle">
          <i class="fa-light fa-folder-open"></i>
        </div>
        <div class="step-text">Documentación</div>
      </div>
      <div class="line"></div>
      <div [ngClass]="{'step': true, 'active': stepper_cuentas == 3}">
        <div class="circle">
          <i class="fa-light fa-signature"></i>
        </div>
        <div class="step-text">Firma</div>
      </div>
    </div>



  </div>

  <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 animate__animated animate__faster animate__fadeIn"></div>

  <!-- Registrar cuenta bancaria -->
  @if(stepper_cuentas == 1) {
  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 animate__animated animate__faster animate__fadeIn">

    <mat-card class="card-simpli">
      <mat-card-content>
        <div *ngIf="!hasAccounts" class="titulo-simpli">agrega
          una cuenta bancaria</div>
        <div *ngIf="!hasAccounts" class="texto-centrado">Registra la cuenta de tu empresa para recibir el
          financiamiento.</div>

        <div *ngIf="hasAccounts" class="titulo-simpli">¡Cuenta
          registrada!</div>
        <div *ngIf="hasAccounts" class="texto-centrado">Puedes revisar o editar los datos de la cuenta antes de
          continuar.</div>

        <div class="container-cuenta" *ngIf="!hasAccounts">
          <div>Cuenta bancaria</div>
          <div class="status-cuenta"><i class="fa-light fa-clock"></i> Pendiente</div>
        </div>

        <div class="container-cuenta" *ngIf="hasAccounts">
          <div>{{capitalizeText(accountData.bank.name)}}</div>
          <div class="status-cuenta-listo">
            <i class="fa-light fa-circle-check"></i> Registrada
            <span (click)="editarCuentaBancaria(accountData.id); $event.stopPropagation()"><i
                class="fa-light fa-pen edit-icon"></i></span>
          </div>
        </div>

        <div class="btn-container">
          <button mat-stroked-button type="button" class="secondary-button" (click)="step = 2;">Volver</button>
          <button *ngIf="!hasAccounts" mat-stroked-button type="button" class="primary-button"
            (click)="agregarCuentaBancaria()">Agregar cuenta bancaria</button>
          <button *ngIf="hasAccounts" mat-stroked-button type="button" class="primary-button"
            (click)="verDocumentos()">Continuar</button>
        </div>


      </mat-card-content>
    </mat-card>

  </div>
  }


  @if(stepper_cuentas == 2) {

  <div *ngIf="isLoading" class="col-lg-6 col-md-6 col-sm-6 col-xs-12 animate__animated animate__faster animate__fadeIn"
    style="margin: 0 auto;">
    <loading-interno></loading-interno>
  </div>

  <div *ngIf="!isLoading"
    class="col-lg-6 col-md-6 col-sm-6 col-xs-12 animate__animated animate__faster animate__fadeIn">
    <mat-card class="card-simpli">
      <mat-card-content>
        <div class="titulo-simpli">completa la documentación legal con los datos que faltan</div>

        <div class="subtitulo-simpli">Documentos completados</div>
        <div class="custom-progress-bar">
          <div class="custom-progress-bar-fill" [style.width.%]="progress"></div>
          <div class="progress-text">{{ progress }}%</div>
        </div>

        <div class="titulo-simpli-doc">Documentos legales</div>

        <div class="container-cuenta-doc">
          <div style="display: flex; align-items: center; cursor: pointer"
            (click)="handleDocumentClick('Declaración jurada', 'view', $event)">
            <div>Declaración jurada</div>
            <div [ngClass]="documentStatus.declaracionJurada.hasData ? 'status-cuenta-listo' : 'status-cuenta-falta'"
              style="display: flex; justify-content: flex-start; align-items: center; margin-left: auto;">
              <i
                [class]="documentStatus.declaracionJurada.hasData ? 'fa-light fa-circle-check' : 'fa-light fa-light fa-clock'"></i>
              {{ documentStatus.declaracionJurada.hasData ? 'Completado' :
              (documentStatus.declaracionJurada.missingFields === 1 ? 'Falta 1 dato' :
              'Faltan ' + documentStatus.declaracionJurada.missingFields + ' datos') }}
              <div style="display: flex; align-items: flex-end; margin-left: 10px;">
                <span><i class="fa-light fa-eye edit-icon"></i></span>
                <span (click)="handleDocumentClick('Declaración jurada', 'download', $event)">
                  <i class="fa-light fa-arrow-down-to-line edit-icon"></i>
                </span>
              </div>
            </div>
          </div>
        </div>


        <div class="container-cuenta-doc">
          <div style="display: flex; align-items: center; cursor: pointer">
            <div>Declaración PEP</div>
            <div class="status-cuenta-listo"
              style="display: flex; justify-content: flex-start; align-items: center; margin-left: auto;">
              <i class="fa-light fa-circle-check"></i> Completado
              <div style="display: flex; align-items: flex-end; margin-left: 10px;">
                <span matTooltip="Ver / Editar documento" matTooltipPosition="below" matTooltipClass="tooltip-doc"
                (click)="handleDocumentClick('Declaración PEP', 'view', $event)">
                  <i class="fa-light fa-eye edit-icon"></i>
                </span>
                <span (click)="handleDocumentClick('Declaración PEP', 'download', $event)"
                  matTooltip="Descargar documento" matTooltipPosition="below" matTooltipClass="tooltip-doc"><i
                    class="fa-light fa-arrow-down-to-line edit-icon"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="container-cuenta-doc">
          <div style="display: flex; align-items: center; cursor: pointer" (click)="step = 5">
            <div>Estado de situación financiera</div>
            <div
              [ngClass]="documentStatus.estadoSituacionFinanciera.hasData ? 'status-cuenta-listo' : 'status-cuenta-falta'"
              style="display: flex; justify-content: flex-start; align-items: center; margin-left: auto;">
              <i
                [class]="documentStatus.estadoSituacionFinanciera.hasData ? 'fa-light fa-circle-check' : 'fa-light fa-light fa-clock'"></i>
              {{ documentStatus.estadoSituacionFinanciera.hasData ? 'Completado' :
              (documentStatus.estadoSituacionFinanciera.missingFields === 1 ? 'Falta 1 dato' :
              'Faltan ' + documentStatus.estadoSituacionFinanciera.missingFields + ' datos') }}
              <div style="display: flex; align-items: flex-end; margin-left: 10px;">
                <span (click)="handleDocumentClick('Estado de situación financiera', 'view', $event)"><i class="fa-light fa-eye edit-icon"></i></span>
                <span (click)="handleDocumentClick('Estado de situación financiera', 'download', $event)">
                  <i class="fa-light fa-arrow-down-to-line edit-icon"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="container-cuenta-doc">
          <div style="display: flex; align-items: center;">
            <div>Rol de avalista</div>
            <div class="status-cuenta-listo"
              style="display: flex; justify-content: flex-start; align-items: center; margin-left: auto;">
              <i class="fa-light fa-circle-check"></i> Completado
              <div style="display: flex; align-items: flex-end; margin-left: 10px;">
                <span (click)="$event.stopPropagation()" matTooltip="Ver / Editar documento" matTooltipPosition="below"
                  matTooltipClass="tooltip-doc"><i class="fa-light fa-eye edit-icon"></i></span>
                <span (click)="$event.stopPropagation()" matTooltip="Descargar documento" matTooltipPosition="below"
                  matTooltipClass="tooltip-doc"><i class="fa-light fa-arrow-down-to-line edit-icon"></i></span>
              </div>
            </div>
          </div>
        </div>

        <div class="titulo-simpli-doc">Poderes legales</div>
        <div class="container-cuenta-doc">
          <div style="display: flex; align-items: center;">
            <div>Carta de poder</div>
            <div class="status-cuenta-listo"
              style="display: flex; justify-content: flex-start; align-items: center; margin-left: auto;">
              <i class="fa-light fa-circle-check"></i> Completado
              <div style="display: flex; align-items: flex-end; margin-left: 10px;">
                <span (click)="$event.stopPropagation()" matTooltip="Ver / Editar documento" matTooltipPosition="below"
                  matTooltipClass="tooltip-doc"><i class="fa-light fa-eye edit-icon"></i></span>
                <span (click)="$event.stopPropagation()" matTooltip="Descargar documento" matTooltipPosition="below"
                  matTooltipClass="tooltip-doc"><i class="fa-light fa-arrow-down-to-line edit-icon"></i></span>
              </div>
            </div>
          </div>
        </div>
        <div class="container-cuenta-doc">
          <div style="display: flex; align-items: center;">
            <div>Poder notarial</div>
            <div class="status-cuenta-listo"
              style="display: flex; justify-content: flex-start; align-items: center; margin-left: auto;">
              <i class="fa-light fa-circle-check"></i> Completado
              <div style="display: flex; align-items: flex-end; margin-left: 10px;">
                <span (click)="$event.stopPropagation()" matTooltip="Ver / Editar documento" matTooltipPosition="below"
                  matTooltipClass="tooltip-doc"><i class="fa-light fa-eye edit-icon"></i></span>
                <span (click)="$event.stopPropagation()" matTooltip="Descargar documento" matTooltipPosition="below"
                  matTooltipClass="tooltip-doc"><i class="fa-light fa-arrow-down-to-line edit-icon"></i></span>
              </div>
            </div>
          </div>
        </div>
        <div class="container-cuenta-doc">
          <div style="display: flex; align-items: center;">
            <div>Poderes bancarios</div>
            <div class="status-cuenta-listo"
              style="display: flex; justify-content: flex-start; align-items: center; margin-left: auto;">
              <i class="fa-light fa-circle-check"></i> Completado
              <div style="display: flex; align-items: flex-end; margin-left: 10px;">
                <span (click)="$event.stopPropagation()" matTooltip="Ver / Editar documento" matTooltipPosition="below"
                  matTooltipClass="tooltip-doc"><i class="fa-light fa-eye edit-icon"></i></span>
                <span (click)="$event.stopPropagation()" matTooltip="Descargar documento" matTooltipPosition="below"
                  matTooltipClass="tooltip-doc"><i class="fa-light fa-arrow-down-to-line edit-icon"></i></span>
              </div>
            </div>
          </div>
        </div>

        <div class="titulo-simpli-doc">Contrato</div>
        <div class="container-cuenta-doc">
          <div style="display: flex; align-items: center;">
            <div>Contrato</div>
            <div class="status-cuenta-listo"
              style="display: flex; justify-content: flex-start; align-items: center; margin-left: auto;">
              <i class="fa-light fa-circle-check"></i> Completado
              <div style="display: flex; align-items: flex-end; margin-left: 10px;">
                <span (click)="$event.stopPropagation()" matTooltip="Ver / Editar documento" matTooltipPosition="below"
                  matTooltipClass="tooltip-doc"><i class="fa-light fa-eye edit-icon"></i></span>
                <span (click)="$event.stopPropagation()" matTooltip="Descargar documento" matTooltipPosition="below"
                  matTooltipClass="tooltip-doc"><i class="fa-light fa-arrow-down-to-line edit-icon"></i></span>
              </div>
            </div>
          </div>
        </div>


        <div class="btn-container">
          <button mat-stroked-button type="button" class="secondary-button"
            (click)="step = 2; originStep = 'stepper_cuentas1'">Volver</button>
          <button mat-stroked-button type="button" class="primary-button" [disabled]="hasPendingDocuments()">Ir a
            firmar</button>
        </div>

      </mat-card-content>
    </mat-card>
  </div>
  }

  <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 animate__animated animate__faster animate__fadeIn"></div>
  }




  <!--TEEUD-->
  @if(step == 0){


  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 animate__animated animate__faster animate__fadeIn">

    <div class="title-container" style="text-align: center;">
      <h1 class="titulo-simpli">Necesitamos que subas unos documentos</h1>
      <small class="subtitulo-simpli">Puedes arrastrar o dar click en la zona correspondiente para subir cada tipo de
        documento</small>
    </div>

    <div>

      <div class="container-img-files"><img src="../../../assets/iconografia/adjuntar documentacion simulacion.png">
      </div>

      <div class="upload-container">

        <div class="dropzone" [class.dragover]="isDragOver1" (dragover)="onDragOver($event, 1)"
          (dragleave)="onDragLeave(1)" (drop)="onFileDrop($event, 1)" (click)="triggerFileInput(1)">

          <p *ngIf="!uploadedFile1">Constitución de la sociedad</p>
          <p *ngIf="uploadedFile1">{{ uploadedFile1.name }}</p>

          <input type="file" id="fileInput1" hidden (change)="onFileSelect($event, 1)" />

        </div>

        <div class="dropzone" [class.dragover]="isDragOver2" (dragover)="onDragOver($event, 2)"
          (dragleave)="onDragLeave(2)" (drop)="onFileDrop($event, 2)" (click)="triggerFileInput(2)">
          <p *ngIf="!uploadedFile2">Inscripción en el conservador de bienes raíces</p>
          <p *ngIf="uploadedFile2">{{ uploadedFile2.name }}</p>
          <input type="file" id="fileInput2" hidden (change)="onFileSelect($event, 2)" />
        </div>

      </div>

      <div class="btn-container" style="margin-top: 40px;">
        <button mat-stroked-button type="button" class="secondary-button" (click)="step = 2">Volver</button>
        <button mat-stroked-button [disabled]="uploadedFile1 == null || uploadedFile2 == null" type="button"
          class="primary-button" (click)="subir_archivos()">Subir documentos</button>
      </div>

    </div>

  </div>

  }

</div>